.actions {
    margin-bottom: 12px;
}
.tx-output {
    min-height: 36px;
    word-break: break-word;
    p {
        margin: 0;
    }
}

.avatar-icon {
    display: inline-block;
    width: 36px;
    height: 36px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('./../../../public/img/avatar-placeholder.png');
    margin: 0px 6px 12px 6px;
}

.response-text {
    font-size: 15px;
    line-height: 20px;
}