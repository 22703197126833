.vial {
    text-align: center;
    .image {
        margin-bottom: 16px;
        padding-bottom: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('./../../public/img/vial.png');
        position: relative;
    }
    .name {
        margin-bottom: 24px;
    }
    .button {
        letter-spacing: 0;
        font-size: 1rem;
        padding: 9px 16px 6px;
    }
    &.hidden {
        opacity: .3;
    }
}

.vial-image {
    width: 50px;
    margin: 36px auto;
}

.collection-title {
    margin-bottom: 24px;
}

.top-title, .top-subtitle {
    font-family: canada-type-gibson, sans-serif;
    font-style: normal;
    color: black;
    text-align: center;
    line-height: 1.4;
}

.top-title {
    font-size: 2.625rem;
    font-weight: 600;
}

.top-subtitle {
    font-size: 1.25rem;
    font-weight: 100;
    margin-top: 32px;
    margin-bottom: 24px;
}

.sold-out-recap {
    text-align: center;
    margin: 32px 0 0 0;
}

.eth-address-wrapper {
    text-align: center;
    word-break: break-all;
    .eth-address {
        color: #fff;
        background: #000;
        display: inline-block;
        font-size: 1rem;
        font-weight: 600;
        padding: 10px 24px 5px 24px;
    }
}

.btn-click {
    .button {
        padding: 9px 24px 7px;
    }
}